////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useState, useMemo, useCallback, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useAuth } from "../../hooks/useAuth"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useModels } from "../../hooks/useModels"
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import { Grid, IconButton, Popover, Checkbox, CircularProgress, InputLabel, FormControl, Select, MenuItem } from "@mui/material"
import TablePageLayout from "../../components/TablePageLayout"
import TablePagination from "../../components/TablePagination"
import DatePicker from "../../components/DatePickerBeta"
import moment from 'moment'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import PdfMerge from '../../components/PdfMerge'
import MultiCheck from "../../components/multiCheck"
import AutocompleteDeluxe from "../../components/AutocompleteDeluxe"
import { HiFolderDownload } from 'react-icons/hi'
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////
import { baseStyles } from "../../utils"
////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import { MdPictureAsPdf } from "react-icons/md"
import { RiFileExcel2Fill } from "react-icons/ri"
import { Search } from "@mui/icons-material"
import Service from '../../services/api'
import Firebase from "../../services/firebase"
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////
import { baseUrl } from "../../utils/variables"
import RangePicker from '../../components/RangePicker'
import InputRegex from '../../components/InputRegex'
import { LoadingButton } from '@mui/lab'
import JSZip from 'jszip'

const useStyles = baseStyles()

const Seguimiento = () => {
  const history = useHistory()
  const classes = useStyles()
  const [perPage, setPerPage] = useState(10)
  const [pagina, setPagina] = useState(1)
  const [zipping, setZipping] = useState(false)
  const [admin] = useLocalStorage("admin", null)
  const { user, permiso } = useAuth()
  const [propiedades, setPropiedades] = useState({
    nombreCompleto: "",
    fechaInicio: "",
    fechaFinal: "",
  })

  const [convenio, setConvenio] = useState(null)

  const parametrosInicialesMemo = useMemo(() => ({
    pagina: 1,
    limite: 10,
    ordenar: 'creado.desc',
    name: "orden-servicio",
    expand: 'detalleConvenio,' +
      'detallePaciente,' +
      'evaluacion.antecedentesLaborales,' +
      'evaluacion.antecedentesPersonales,' +
      'evaluacion.interrogatorio,' +
      'evaluacion.exploracion,' +
      'evaluacion.accidentes,' +
      'evaluacion.paraclinicos,' +
      'evaluacion.laboratorios,' +
      'evaluacion.estudiosGabinete,' +
      'evaluacion.comentarios,' +
      'evaluacion.audioValores,' +
      'evaluacion.entradaInventario,' +
      'evaluacion.padecimiento,' +
      'evaluacion.estudiosGabinete.archivosRadios,' +
      'evaluacion.ordenServicio.detallePaciente,' +
      'evaluacion.ordenServicio.detalleConvenio,' +
      'evaluacion.ordenServicio.ordenServicioDoctores.doctor,' +
      'evaluacion.ordenServicio.ordenServicioDoctores,',
    extraParams: {
      porUsuario: "true",
      ...propiedades
    }
  }), [propiedades])

  const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel] = useModels({ ...parametrosInicialesMemo })
  const [conveniosPU, setConveniosPU] = useState(null)
  const changePage = async page => {
    setPagina(page + 1)
    let params = { ...parametrosInicialesMemo, convenio: convenio?.idConvenio, ...{ pagina: page + 1, limite: perPage }, }
    await refreshModels({ isCargando: true, params })
  }

  const changePageRow = async per => {
    setPerPage(per)
    const params = { ...parametrosInicialesMemo, convenio: convenio?.idConvenio, ...{ limite: per, pagina: pagina }, }
    await refreshModels({ isCargando: true, params })
  }

  const getFiles = _row => {
    let _files = []
    _files.push({
      row: _row,
      url: `https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FLogo.png?alt=media&token=46f97c79-3b08-4c2f-a277-c086784c16b7`,
      tipo: "Evaluacion",
      tipoN: 1,
      tipoArchivo: "application/pdf",
    })
    if (_row?.estudiosGabinete) {
      if (_row?.estudiosGabinete?.urlCustomDoc) {
        _files.push({
          url: _row?.estudiosGabinete?.urlCustomDoc,
          tipo: "Evaluacion",
          tipoN: 0,
          tipoArchivo: "application/pdf",
        })
      }
    }
    _files.push({
      row: _row,
      url: `https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sistema%2FLogo.png?alt=media&token=46f97c79-3b08-4c2f-a277-c086784c16b7`,
      tipo: "Evaluacion",
      tipoN: 2,
      tipoArchivo: "application/pdf",
    })
    if (_row?.estudiosGabinete) {
      if (_row?.estudiosGabinete?.archivosRadios) {
        if (_row?.estudiosGabinete?.archivosRadios.length > 0) {
          _row?.estudiosGabinete?.archivosRadios.forEach(radio => {
            _files.push({
              url: "EstudiosGabinete/" + radio.firebaseName,
              tipo: "Radio",
              tipoArchivo: radio.type,
            })
          })
        }
      }
    }
    if (_row?.laboratorios?.urlArchivo) {
      _files.push({
        url: _row?.laboratorios.urlArchivo,
        tipo: "Laboratorio",
        tipoArchivo: "application/pdf",
      })
    }
    return _files
  }

  useEffect(async () => {
    let res = await Service.get("convenio-usuario?idUsuario=" + user?.idUsuario + "&expand=convenio")
    setConveniosPU(res?.resultado)
  }, [])

  const onSearchClicked = async eliminado => {
    let params = {}
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        convenio: convenio?.idConvenio,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      }
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        convenio: convenio?.idConvenio,
        ...{ pagina: pagina, limite: perPage },
      }
    }
    await refreshModels({ isCargando: true, params })
  }

  const getIsDisabled = _row => {
    if (_row?.idSucursal == 2 || _row?.status != "FINALIZADO") {
      return true
    }
    return false
  }

  const getDatosFiltrados = _data => {
    let dataBusqueda = []
    _data.forEach(d => {
      if (convenio) {
        if (d?.detalleConvenio?.idConvenio === convenio?.idConvenio) {
          dataBusqueda.push(d)
        }
      } else {
        dataBusqueda.push(d)
      }
    })

    let dataBusquedaInicio = []

    dataBusqueda.forEach(d => {
      if (propiedades?.fechaInicio != "" && propiedades?.fechaFinal != "") {
        if (moment(d?.creado).isBetween(propiedades?.fechaInicio, propiedades?.fechaFinal)) {
          dataBusquedaInicio.push(d)
        }
      } else {
        dataBusquedaInicio.push(d)
      }
    })
    let filtroFinal = []
    dataBusquedaInicio.forEach(d => {
      if (propiedades?.nombreCompleto != "") {
        if (d?.detallePaciente?.nombreCompleto?.includes(propiedades?.nombreCompleto)) {
          filtroFinal.push(d)
        }
      } else {
        filtroFinal.push(d)
      }
    })
    return filtroFinal
  }

  const downloadZip = async () => {
    setZipping(true)
    if (models?.length > 0) {
      let zip = new JSZip();
      let toGetBlobs = models.filter(m => {
        return m?.status == "FINALIZADO"
      })
      console.log(toGetBlobs)
      if (toGetBlobs?.length > 0) {
        for await (let toGetBlob of toGetBlobs) {
          let pdf = await Firebase.getBlobFromFiles(getFiles(toGetBlob?.evaluacion))
          zip.file(pdf?.name, pdf?.file)
        }
      }
      zip.generateAsync({ type: "blob" }).then(content => {
        window.open(window.URL.createObjectURL(content), "_blank")
      });
    }
    setZipping(false)
  }


  return (
    <>
      <TablePageLayout
        title="Seguimiento"
        model="seguimiento"
        history={history}
        links={[{ label: "Seguimiento", to: "/segumientos", icon: <QueryStatsIcon fontSize="small" /> }]}
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        SearchComponents={
          <>
            <Grid item xs={12} md={6}>
              <DatePicker
                labelText="Fecha Inicio"
                variant="standard"
                fullWidth
                typePicker="mobile"
                inputFormat="dd/MM/yyyy"
                size="medium"
                value={propiedades.fechaInicio != "" ? propiedades.fechaInicio : null}
                onChange={(e) => setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaInicio: moment(e).format("YYYY-MM-DD H:m:s"),
                }))}
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                labelText="Fecha Final"
                variant="standard"
                fullWidth
                typePicker="mobile"
                inputFormat="dd/MM/yyyy"
                size="medium"
                value={propiedades.fechaFinal != "" ? propiedades.fechaFinal : null}
                onChange={(e) => setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaFinal: moment(e).format("YYYY-MM-DD H:m:s"),
                }))}
              />

            </Grid>
            {
              conveniosPU?.length > 1 && (
                <Grid item xs={12} >
                  {/* <AutocompleteDeluxe
                    labelToShow="Convenio"
                    labelProp="nombreConvenio"
                    nombre="convenio"
                    value={convenio}
                    setDato={setConvenio}
                  /> */}
                  <FormControl fullWidth variant='standard'>
                    <InputLabel id="didconvenio">Convenio</InputLabel>
                    <Select
                      labelId="didconvenio"
                      id="dconvenio"
                      value={convenio}
                      label="Convenio"
                      onChange={(e)=>{
                        setConvenio(e.target.value)
                      }}
                    >
                      <MenuItem value={null}>TODOS</MenuItem>
                      {
                        conveniosPU?.map((c, i) => (
                          <MenuItem key={i} value={c}>{c?.convenio.nombreConvenio}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
            <Grid item xs={12} sm={12}>
              <InputRegex
                label="Nombre Completo"
                variant="standard"
                fullWidth
                size="medium"
                typeInput='text'
                value={propiedades?.nombreCompleto}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreCompleto: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", }}>
              <Grid item xs={1} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="error" aria-label="upload picture" component="span">
                  <a className={classes.btnPdf} target="_blank" rel="noreferrer"
                    href={`${baseUrl}pdf/seguimiento?
                    ${"idUsuario=" + user?.idUsuario}
                    ${"&idConvenio=" + convenio?.idConvenio}
                    ${"&fechaInicio=" + propiedades?.fechaInicio}
                    ${"&fechaFinal=" + propiedades?.fechaFinal}
                    `}>
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={1} style={{ isplay: "flex", flexDirection: "row", alignItems: "center", }} >
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer"
                    href={`${baseUrl}excel/seguimiento?
                    ${"idUsuario=" + user?.idUsuario}
                    ${"&idConvenio=" + convenio?.idConvenio}
                    ${"&fechaInicio=" + propiedades?.fechaInicio}
                    ${"&fechaFinal=" + propiedades?.fechaFinal}
                    `}>
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
              {
                permiso[0]["doctor"] > 0 &&
                <Grid item xs={1} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                {
                  !zipping
                    ?
                    <IconButton onClick={() => { downloadZip() }}>
                      <HiFolderDownload style={{ color: "#DAD083" }} />
                    </IconButton>
                    :
                    <CircularProgress />
                }
              </Grid>
              }
            </Grid>
          </>
        }
        TableComponent={
          <TablePagination
            nombrePermiso="seguimientos"
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "creado",
                name: "Fecha",
                sortable: true,
                cell: (row, index) => <p key={index}>{moment(row?.creado).format("dddd DD MMMM YYYY")}</p>
              },
              {
                prop: "nombreConvenio",
                name: "Empresa",
                sortable: true,
                cell: (row, index) => <p key={index}>{row?.detalleConvenio?.nombreConvenio}</p>
              },
              {
                prop: "nombreCompleto",
                name: "Nombre Completo",
                sortable: true,
                cell: (row, index) => <p key={index}>{row?.detallePaciente?.nombreCompleto}</p>
              },
              {
                prop: "comentario",
                name: "Comentario",
                sortable: true,
                cell: (row, index) => <p key={index}>
                  {

                    row?.idSucursal == 2
                      ?
                      "NO APLICA"
                      :
                      row?.evaluacion?.comentarios
                        ?
                        row?.evaluacion?.comentarios?.evaluacion
                        :
                        "Sin Definir"
                  }
                </p>
              },
              {
                prop: "changeEstudio",
                name: "Seguimiento",
                cell: (row, index) => (
                  <PopupState variant="popover" popupId="demo-popup-popover1" >
                    {(popupState) => (
                      <div>
                        <IconButton variant="contained" {...bindTrigger(popupState)}>
                          <MultiCheck options={[row?.evaluacion?.laboratorios, row?.evaluacion?.exploracion, row?.evaluacion?.audioValores, row?.evaluacion?.estudiosGabinete]} />
                        </IconButton>
                        <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'top', horizontal: 'center', }} transformOrigin={{ vertical: 'bottom', horizontal: 'center', }} >
                          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                              <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                <div> <strong>Laboratorio</strong> </div>
                                <div>
                                  <Checkbox
                                    checked={row?.evaluacion?.laboratorios}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                              <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                <div> <strong>Medicina General</strong> </div>
                                <div>
                                  <Checkbox
                                    checked={row?.evaluacion?.exploracion}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                              <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                <div> <strong>Audiometria</strong> </div>
                                <div>
                                  <Checkbox
                                    checked={row?.evaluacion?.audioValores}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                  />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%', padding: '5px' }}>
                              <div style={{ display: 'flex', alignItems: 'center', background: '#1D5E7B', flexDirection: 'row', justifyContent: 'space-between', padding: '0 30px', color: '#FFF', borderRadius: '5px' }}>
                                <div> <strong>Rayos X</strong> </div>
                                <div>
                                  <Checkbox
                                    checked={row?.evaluacion?.estudiosGabinete}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#fff !important' } }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </div>
                        </Popover>
                      </div>
                    )}
                  </PopupState >
                )
              },
              {
                prop: 'pdfc',
                name: 'PDF Evaluacion',
                cell: (row, index) => (
                  <>
                    < PdfMerge disabled={permiso[0]["doctor"] > 0 ? getIsDisabled(row) : true} files={getFiles(row?.evaluacion)} />
                  </>
                )
              },
            ]}
          />
        }
      />
    </>
  )
}

export default Seguimiento


